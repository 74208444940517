import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

const defaultProps = {
    tag: "section",
    className: "test-section",
    id: "",
    title: "",
    subtitle: ""
};

const propTypes = {
    tag: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired
};

const Section = ({ tag: Tag, className, id, title, subtitle }) => {
    const classes = classNames(className);
    const sectionId = id;
    return (
        <Tag className={classes} id={sectionId}>
            <h1 className="section-title">{title}</h1>
            <p>{subtitle}</p>
        </Tag>
    );
};

Section.defaultProps = defaultProps;
Section.propTypes = propTypes;

export default Section;
