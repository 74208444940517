import React, { Component } from "react";
import Section from "../../components/Section/Section";

const PulseProtocol = props => {
	return (
		<section
			className="cp-pulseprotocol bg-white"
			id="pulseprotocol"
			// title="PULSE Protocol"
		>
			<div className="container">
				<div className="row align-items-center py-5">
					<div className="col-12">
						<h2 className="text-center pb-5">PULSE Protocol</h2>
					</div>
					<div className="col-12 col-md-6 order-2 order-md-1">
						<img
							className="img-fluid"
							src={
								process.env.PUBLIC_URL +
								"/img/pulse_protocol_main.png"
							}
							alt="Population projections diagram"
						/>
					</div>
					<div className="col-12 col-md-6 order-1 order-md-2">
						<h6 className="mb-4">
							We designed the City PULSE protocol to motivate and
							incentivize participants in shaping a better future.
						</h6>
						<p>
							The PULSE platform will be a ground base and
							technical infrastructure upon which anyone with an
							important, SDG-compliant idea, can build their
							application. It is a transparent, decentralized and
							secure solution developed to help educate and,
							through the reward system, further atract people
							toward sustainability and a beter lifestyle.
						</p>
					</div>
				</div>
				<div className="row align-items-center justify-content-center py-5">
					<div className="col-11 col-md-9">
						<div className="row align-items-center pb-5">
							<div className="col-12">
								<p className="text-md-center pb-5">
									In order to kickstart the project, we will
									be shipping our protocol along with several
									pre-built applications, providing
									inspiration for others in developing new
									solutions
								</p>
							</div>
							<div className="col-12 col-md-6 text-md-right">
								<h6 className="mb-4">
									Cycling and carpooling for clean air
								</h6>
								<p className="mb-4">
									We think that if citizens are rewarded for
									taking bicycles to get to work or carpooling
									(ride sharing), we will reduce polution and
									traffic jams.
								</p>
								<p>
									Our platform will initially have an
									application which will reward them for
									cycling or carpooling.
								</p>
							</div>
							<div className="col-12 col-md-6">
								<img
									className="img-fluid"
									src={
										process.env.PUBLIC_URL +
										"/img/cycling_graph.png"
									}
									alt="Cycling and carpooling diagram"
								/>
							</div>
						</div>
					</div>
					<div className="col-12">
						<hr />
					</div>
				</div>

				<div className="row align-items-center justify-content-center py-5">
					<div className="col-11 col-md-9">
						<div className="row align-items-center pb-5">
							<div className="col-12 col-md-6 order-2 order-md-1">
								<img
									className="img-fluid"
									src={
										process.env.PUBLIC_URL +
										"/img/education_graph.png"
									}
									alt="Education diagram"
								/>
							</div>
							<div className="col-12 col-md-6 order-1 order-md-2">
								<h6 className="mb-4">Education for all</h6>
								<p className="mb-4">
									Our protocol will be shipping and offering
									the infrastructure for all its users to
									share knowledge about educating local
									governments and busineses on how to be
									compliant with these goals and by educating
									the media on how to understand and report on
									them through the application for creating
									offline or online courses, and related
									events.
								</p>
							</div>
						</div>
					</div>
					<div className="col-12">
						<hr />
					</div>
				</div>

				<div className="row align-items-center justify-content-center py-5">
					<div className="col-11 col-md-9">
						<div className="row align-items-center text-md-right pb-5">
							<div className="col-12 col-md-6 ">
								<h6 className="mb-4">Recycling</h6>
								<p className="mb-4">
									If recycling efforts were to become
									effective, there has to be an incentive for
									citizens to make some modification in their
									daily lives when it comes to their trash
									disposal habits. Therefore we will be
									shipping an impartial reward application for
									citizens who recycle.
								</p>
							</div>

							<div className="col-12 col-md-6">
								<img
									className="img-fluid"
									src={
										process.env.PUBLIC_URL +
										"/img/recycling_graph.png"
									}
									alt="Recycling diagram"
								/>
							</div>
						</div>
					</div>
					<div className="col-12">
						<hr />
					</div>
				</div>

				<div className="row align-items-center justify-content-center pt-5">
					<div className="col-11 col-md-9">
						<div className="row align-items-center pb-5">
							<div className="col-12 col-md-6 order-2 order-md-1">
								<img
									className="img-fluid"
									src={
										process.env.PUBLIC_URL +
										"/img/goverment_graph.png"
									}
									alt="Goverment diagram"
								/>
							</div>
							<div className="col-12 col-md-6 order-1 order-md-2">
								<h6 className="mb-4">
									Local goverment support
								</h6>
								<p className="mb-4">
									The key to all efforts is to actively
									include local governments in to the project.
									If we manage to attract enough people to
									cycle or carpool, local government could
									support it by building more carpooling or
									cycling lanes.
								</p>
								<p>
									We will be providing an application through
									which local authorities or citizens can
									suggest and vote for the best proposals and
									next city projects.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
export default PulseProtocol;
