import React from "react";
import "./App.scss";
import Navigation from "./containers/Navigation/Navigation";
import Home from "./containers/Home/Home";
import Introduction from "./containers/Introduction/Introduction";
import Goals from "./containers/Goals/Goals";
import Conclusion from "./containers/Conclusion/Conclusion";
import PulseProtocol from "./containers/PulseProtocol/PulseProtocol";
import Roadmap from "./containers/Roadmap/Roadmap";
import Team from "./containers/Team/Team";
import Contact from "./containers/Contact/Contact";
import Footer from "./containers/Footer/Footer";

function App() {
    return (
        <div className="App">
            <Navigation />
            <Home />
            <Introduction />
            <Goals />
            <PulseProtocol />
            <Team />
            <Roadmap />
            <Contact />
            <Conclusion />
            <Footer />
        </div>
    );
}

export default App;
