import React from "react";
import PropTypes from "prop-types";

const defaultProps = {
	image:
		"https://cdn.pixabay.com/photo/2016/08/20/05/38/avatar-1606916__340.png",
	name: "John Doe",
	title: "CEO",
	fbLink: "https://facebook.com",
	liLink: "https://linkedin.com"
};

const propTypes = {
	image: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired
};

const Card = ({ image, name, title, fbLink, liLink }) => {
	return (
		<div className="card h-100">
			<img src={image} className="card-img-top" alt={name} />
			<div className="card-body d-flex flex-column justify-content-between text-center">
				<div className="mb-3">
					<h5>{name}</h5>
					<p>
						<span>{title}</span>
					</p>
				</div>
				<div className="card-social_links">
					<a href={fbLink} target="_blank" className="p-1 mx-1">
						<svg
							aria-hidden="true"
							focusable="false"
							role="img"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 320 512"
							widht="20"
							height="20"
						>
							<path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path>
						</svg>
					</a>
					<a href={liLink} target="_blank" className="p-1 mx-1 ">
						<svg
							aria-hidden="true"
							focusable="false"
							xmlns="http://www.w3.org/2000/svg"
							x="0px"
							y="0px"
							viewBox="0 0 448 512"
							widht="20"
							height="20"
						>
							<path d="M101.3,512H8.4V212.9h92.9V512z M54.8,172.1C25.1,172.1,1,147.5,1,117.8C1,88.1,25.1,64,54.8,64c29.7,0,53.8,24.1,53.8,53.8 C108.6,147.5,84.5,172.1,54.8,172.1z M448.9,512h-92.7V366.4c0-34.7-0.7-79.2-48.3-79.2c-48.3,0-55.7,37.7-55.7,76.7V512h-92.8 V212.9h89.1v40.8h1.3c12.4-23.5,42.7-48.3,87.9-48.3c94,0,111.3,61.9,111.3,142.3V512H448.9z" />
						</svg>
					</a>
				</div>
			</div>
		</div>
	);
};

Card.defaultProps = defaultProps;
Card.propTypes = propTypes;

export default Card;
