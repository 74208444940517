import React, { Component } from "react";
// import Section from "../../components/Section/Section";

const Introduction = props => {
	return (
		<section
			className="cp-introduction bg-white"
			// title="Introduction & Background"
			id="introduction"
		>
			<div className="container">
				<h2 className="text-center pb-5">Introduction & Background</h2>
				<div className="row align-items-center">
					<div className="col-12 col-md-6">
						<img
							className="img-fluid"
							src={process.env.PUBLIC_URL + "/img/diagram.png"}
							alt="Population projections diagram"
						/>
					</div>
					<div className="col-12 col-md-6">
						<p className="mb-4">
							The world as we know it is rapidly changing and
							developing. These changes are more noticeable in
							cities, due to the fact that 60% of people live in
							them and it is estimated that by 2050, additional 6
							billion people will have moved to the city, in the
							search for beter education, job opportunity, or a
							diferent lifestyle.
						</p>
						<p>
							Because of it, city life is becoming more difficult
							due to overpopulation, food, energy and
							infrastructure requirements, increased polution and
							traffic blockage. In order to addres these isues,
							the UN has been discussing multiple elements that
							society has to strive for, in an atempt to improve
							the curent situation.
						</p>
					</div>
				</div>
			</div>
		</section>
	);
};
export default Introduction;
