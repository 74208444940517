import React, { Component } from "react";

const Footer = props => {
	return (
		<section className="cp-footer">
			<img
				className="cp_symbol"
				src={process.env.PUBLIC_URL + "/img/symbol_cp.png"}
				alt="CityPuls Logo"
			/>
		</section>
	);
};
export default Footer;
