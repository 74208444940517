import React, { Component } from "react";

const Conclusion = props => {
	return (
		<section className="cp-conclusion" id="conclusion">
			<div className="container">
				<div className="row justify-content-center mb-3">
					<div className="col-12">
						<h2 className="text-center pb-4">Conclusion</h2>
					</div>
					<div className="col-12 col-md-8">
						<p className="text-center pb-4">
							Since the world’s effort for reaching Sustainable
							Development Goals is well underway, it is important
							for everyone to start thinking globally and to start
							acting locally. With the City PULSE protocol,
							everybody can start an important initiative to help
							cities become more sustainable and shape the future
						</p>
					</div>
				</div>
			</div>
		</section>
	);
};
export default Conclusion;
