import React, { Component } from "react";

const Contact = props => {
	return (
		<section className="cp-contact bg-light" id="contact">
			<div className="container">
				<div className="row justify-content-center mb-3">
					<div className="col-12">
						<h2 className="text-center pb-4">Contact Us</h2>
					</div>
					<div className="col-12 col-lg-6">
						<form name="contact" netlify>
							<div className="form-group">
								<input
									type="text"
									className="form-control form-control-lg border-primary rounded-pill px-4"
									name="name"
									placeholder="Name"
									required
								/>
							</div>
							<div className="form-group">
								<input
									type="email"
									className="form-control form-control-lg border-primary rounded-pill px-4"
									name="email"
									placeholder="Email"
									required
								/>
							</div>
							<div className="form-group">
								<input
									type="text"
									className="form-control form-control-lg border-primary rounded-pill px-4"
									name="subject"
									placeholder="Subject"
									required
								/>
							</div>
							<div className="form-group">
								<textarea
									className="form-control form-control-lg border-primary rounded-pill px-4"
									name="message"
									placeholder="Message"
									required
								/>
							</div>
							<div className="text-center mt-4 w-100">
								<button
									type="submit"
									className="btn btn-outline-primary btn-lg rounded-pill px-5"
								>
									Submit
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</section>
	);
};
export default Contact;
