import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class PreviousNextMethods extends Component {

	next = () => {
		this.slider.slickNext();
	}
	previous = () => {
		this.slider.slickPrev();
	}
	render() {
		const settings = {
			dots: false,
			arrows: false,
			infinite: true,
			speed: 1000,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 5000,
			// adaptiveHeight: true
			fade: true
			// lazyLoad: true
		};
		return (
			<section className="cp-home bg-white" id="home">
				<div className="container py-5">
					<div className="position-relative cp-slick">
						<Slider
							ref={c => (this.slider = c)}
							{...settings}
							className="teste"
						>
							<div className="row h-100 d-flex w-auto">
								<div className="col-12 col-md-6 d-flex flex-wrap align-items-center mt-4">
									<div className="w-100 text-justify text-md-left">
										<h1 className="cp-slick-title text-center text-md-left">
											City Protocol for Urban Life
											Sustainability Empowerment
										</h1>

										<p className="cp-slick-subtitle text-justify text-md-left">
											We designed the City PULSE protocol
											to motivate and incentivize
											participants in shaping a better
											future.
										</p>
									</div>
									{/*<a
										href="#"
										className="cp-slick-btn align-self-end"
									>
										Learn More
									</a>*/}
								</div>
								<div className="col-md-6 d-md-flex justify-content-between align-items-center h-100 d-none">
									<img
										alt="Main map"
										src={
											process.env.PUBLIC_URL +
											"/img/main_map.png"
										}
										className="cp-slick-img"
									/>
								</div>
							</div>
							<div className="row h-100 d-flex w-auto">
								<div className="col-12 col-md-6 d-flex flex-wrap align-items-center mt-4">
									<div className="w-100">
										<h1 className="cp-slick-title">
											City Protocol for Urban Life
											Sustainability Empowerment
										</h1>
										<p className="cp-slick-subtitle">
											With the City PULSE protocol,
											everybody can start an important
											initiative to help cities become
											more sustainable and shape the
											future
										</p>
									</div>
									{/*<a href="#" className="cp-slick-btn align-self-end">
										Learn More
									</a>*/}
								</div>
								<div className="col-md-6 d-md-flex justify-content-between align-items-center h-100 d-none">
									<img
										alt="Main map"
										src={
											process.env.PUBLIC_URL +
											"/img/main_globe.png"
										}
										className="cp-slick-img"
									/>
								</div>
							</div>
						</Slider>
						<div className="cp-slick-nav">
							<a
								className="cp-slick-btn-prev"
								onClick={this.previous}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 15 24"
								>
									<path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z" />
								</svg>
							</a>
							<a
								className="cp-slick-btn-next"
								onClick={this.next}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 15 24"
								>
									<path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z" />
								</svg>
							</a>
						</div>
					</div>
				</div>
			</section>
		);
	}
}
