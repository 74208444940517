import React from "react";
// import Section from "../../components/Section/Section";

const Goals = props => {
	return (
		<section
			className="cp-goals bg-light"
			id="goals"
			// title="Sustainable Development Goals"
		>
			<div className="container">
				<h2 className="text-center pb-5">
					Sustainable Development Goals
				</h2>
				<p className="text-md-center pb-5">
					Sustainable Development Goals(SDG), set up by the UN in
					2015, are created in a form of guidelines, bound to improve
					the curent situation on the planet. They have the purpose of
					creating a more sustainable and safer alternative to
					everyone’s daily life. Cities are an important part of these
					goals, as they are the first to have to adapt and live up to
					the challenges.
				</p>
				<div className="row align-items-center">
					<div className="col-12 col-md-5 d-flex flex-column justify-items-around text-md-right">
						<h6 className="mb-4">
							We think that it is important for cities to start
							cooperating with their citizens, bussineses and
							visitors in order to motivate them to participate.
						</h6>
						<p>
							Cities have to become more interactive and adaptive
							while citizens have to acept the changes.
						</p>
					</div>
					<div className="col-12 col-md-7">
						<img
							className="img-fluid"
							src={process.env.PUBLIC_URL + "/img/goals.png"}
						/>
					</div>
				</div>
			</div>
		</section>
	);
};
export default Goals;
