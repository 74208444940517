import React, { Component } from "react";

const Roadmap = props => {
	return (
		<section className="cp-roadmap" id="roadmap">
			<div className="container">
				<div className="row justify-content-center mb-3">
					<div className="col-12">
						<h2 className="text-center pb-4">
							Roadmap & Project Timelines
						</h2>
					</div>
					<div className="col-12 col-md-8">
						<p className="text-md-center pb-4">
							We have a lot of work ahead of us, and currently, we
							are in the design and prototype development phase of
							our applications. Our estimate is that in the
							following 3-6 months our platform will reach minimum
							viable product.
						</p>
					</div>
				</div>
				<div className="row align-items-center justify-content-center mb-3">
					<div className="col-12">
						<h5 className="text-center pb-5">
							The Project time line is as follows:
						</h5>
					</div>
					<div className="col-12">
						<div className="text-center pb-5">
							<img
								className="img-fluid d-none d-sm-block"
								src={
									process.env.PUBLIC_URL + "/img/timeline.png"
								}
								alt="CityPuls timeline"
							/>
							<img
								className="img-fluid d-block d-sm-none"
								src={
									process.env.PUBLIC_URL +
									"/img/timeline_vertical.png"
								}
								alt="CityPuls timeline"
							/>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
export default Roadmap;
