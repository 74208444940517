import React, { Component } from "react";
import Card from "../../components/Card/Card";

class Team extends Component {
	state = {
		team_members: [
			{
				image: process.env.PUBLIC_URL + "/img/igor_velickovic.jpg",
				name: "Igor Velickovic",
				title: "CEO, co-founder",
				fbLink: "https://www.facebook.com/igor.velickovic",
				liLink: "https://www.linkedin.com/in/igor-velickovic/"
			},
			{
				image: process.env.PUBLIC_URL + "/img/branko_krsmanovic.jpg",
				name: "Branko Krsmanovic",
				title: "COO, co-founder",
				fbLink: "https://www.facebook.com/branko.krsmanovic.5",
				liLink: "https://www.linkedin.com/in/branko-krsmanovic-3a508485"
			},
			{
				image: process.env.PUBLIC_URL + "/img/rade_radivojevic.jpg",
				name: "Rade Radivojevic",
				title: "Developer",
				fbLink: "https://www.facebook.com/rade.radivojevic.71",
				liLink:
					"https://www.linkedin.com/in/rade-radivojevic-50915612b/"
			},
			{
				image: process.env.PUBLIC_URL + "/img/vladana_donevski.jpg",
				name: "Vladana Donevski",
				title: "Community manager",
				fbLink: "https://www.facebook.com/vladana.donevski",
				liLink: "https://www.linkedin.com/in/vladanadonevski/"
			},
			{
				image: process.env.PUBLIC_URL + "/img/pedja_mijatovic.jpg",
				name: "Pedja Mijatovic",
				title: "Devops",
				fbLink: "https://facebook.com",
				liLink: "https://linkedin.com"
			},
			{
				image: process.env.PUBLIC_URL + "/img/nebojsa_nikolic.jpg",
				name: "Nebojsa Nikolic",
				title: "Developer",
				fbLink: "https://www.facebook.com/nikolic.nebojsa",
				liLink: "https://www.linkedin.com/in/nebojsa-nikolic-ab9193122/"
			},
			{
				image: process.env.PUBLIC_URL + "/img/stojan_ivanisevic.jpg",
				name: "Stojan Ivanisevic",
				title: "Business analyst",
				fbLink: "https://www.facebook.com/stojan.ivanisevic",
				liLink: "https://www.linkedin.com/in/stojanivanisevic/"
			},
			{
				image: process.env.PUBLIC_URL + "/img/vladimir_stoimenov.jpg",
				name: "Vladimir Stoimenov",
				title: "Design and development",
				fbLink: "https://facebook.com",
				liLink:
					"https://www.linkedin.com/in/vladimir-stoimenov-980b5a172/"
			}
		]
	};
	render() {
		return (
			<section className="cp-team bg-light" id="team">
				<div className="container">
					<div className="row justify-content-center mb-3">
						<div className="col-12">
							<h2 className="text-center pb-4">Our Team</h2>
						</div>
						<div className="col-12 col-md-8">
							<p className="text-md-center pb-4">
								We are proud to work with those brilliant people
								willing to focus 100% of their time to make a
								better future for all of us.
							</p>
						</div>
					</div>
					<div className="row justify-content-center align-items-stretch">
						{this.state.team_members.map(member => {
							const {
								image,
								name,
								title,
								fbLink,
								liLink
							} = member;
							return (
								<div
									className="col-10 col-sm-6 col-lg-3 mb-4"
									key={name}
								>
									<Card
										name={name}
										title={title}
										image={image}
										fbLink={fbLink}
										liLink={liLink}
									/>
								</div>
							);
						})}
					</div>
				</div>
			</section>
		);
	}
}
export default Team;
