import React, { Component } from "react";
import { NavHashLink as NavLink } from "react-router-hash-link";

const Navigation = props => {
    return (
        <nav className="cp-nav">
            <div className="row">
                <div className="col-12 col-xl-2 d-flex">
                    <a className="navbar-brand px-md-4" href="/">
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/img/horizontal_logo.png"
                            }
                            alt="CityPuls Logo"
                        />
                    </a>
                    <button
                        className="navbar-toggler ml-auto"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarToggler"
                        aria-controls="navbarToggler"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                </div>
                <div className="col col-xl-8 d-flex align-items-center">
                    <div
                        className="collapse navbar-collapse"
                        id="navbarToggler"
                    >
                        <ul className="navbar-nav mx-auto mt-2 mt-lg-0">
                            <li className="nav-item">
                                <NavLink
                                    className="nav-link"
                                    to="#introduction"
                                    activeClassName="selected"
                                    scroll={el =>
                                        el.scrollIntoView({
                                            behavior: "smooth",
                                            block: "start"
                                        })
                                    }
                                >
                                    Introduction
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    className="nav-link"
                                    to="#goals"
                                    activeClassName="selected"
                                    scroll={el =>
                                        el.scrollIntoView({
                                            behavior: "smooth",
                                            block: "start"
                                        })
                                    }
                                >
                                    Goals
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    className="nav-link"
                                    activeClassName="selected"
                                    scroll={el =>
                                        el.scrollIntoView({
                                            behavior: "smooth",
                                            block: "start"
                                        })
                                    }
                                    to="#pulseprotocol"
                                >
                                    Pulse protocol
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    className="nav-link"
                                    to="#team"
                                    activeClassName="selected"
                                    scroll={el =>
                                        el.scrollIntoView({
                                            behavior: "smooth",
                                            block: "start"
                                        })
                                    }
                                >
                                    Our team
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    className="nav-link"
                                    to="#roadmap"
                                    activeClassName="selected"
                                    scroll={el =>
                                        el.scrollIntoView({
                                            behavior: "smooth",
                                            block: "start"
                                        })
                                    }
                                >
                                    Roadmap
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    className="nav-link"
                                    to="#contact"
                                    activeClassName="selected"
                                    scroll={el =>
                                        el.scrollIntoView({
                                            behavior: "smooth",
                                            block: "start"
                                        })
                                    }
                                >
                                    Contact Us
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    className="nav-link"
                                    to="#conclusion"
                                    activeClassName="selected"
                                    scroll={el =>
                                        el.scrollIntoView({
                                            behavior: "smooth",
                                            block: "start"
                                        })
                                    }
                                >
                                    Conclusion
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    );
};
export default Navigation;
